<template>
  <div class="content">
    <div class="content-left">
      <ul>
        <li>
          <router-link to="/admin">
            <div class="content-left-item">
              <img src="@/assets/home.png" alt="dashboard" />
              <span>看板</span>
            </div>
          </router-link>
        </li>
        <!-- <li>
          <router-link to="/admin/user">
            <div class="content-left-item">
              <img src="@/assets/user.png" alt="dashboard" />
              <span>用户</span>
            </div>
          </router-link>
        </li> -->
        <li>
          <div class="content-left-item" style="display:block;margin-top:60px;">
            <p class="content-left-info-title">OA数据截至：</p>
            <p class="content-left-info-value">{{ oaDataTime }}</p>
            <p class="content-left-info-title">上次更新时间：</p>
            <p class="content-left-info-value">{{ lastUpdateTime }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="content-right">
      <AdminDashboardView
        v-if="$route.name === 'admin'"
      />
      <AdminSalesmanView
        v-else-if="$route.name === 'salesman'"
      />
    </div>
  </div>
</template>

<script lang="javascript">
import moment from 'moment';
import admin from '@/lib/admin';
import AdminDashboardView from './AdminDashboardView.vue';
import AdminSalesmanView from './AdminSalesmanView.vue';

export default {
  name: 'AdminContentView',
  components: {
    AdminDashboardView,
    AdminSalesmanView,
  },
  data() {
    return {
      oaDataTime: '',
      lastUpdateTime: '',
    };
  },
  mounted() {
    this.getMiscInfo();
  },
  methods: {
    getMiscInfo() {
      admin.getMiscInfo().then((res) => {
        this.oaDataTime = res.result.oa_data_time;
        this.lastUpdateTime = moment(parseInt(res.result.perform_time, 10) * 1000).format('YYYY-MM-DD HH:mm:ss');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: #F6F7FA;
  display: flex;

  &-left {
    background-color: #ffffff;
    width: 236px;

    &-item {
      display: flex;
      align-items: center;
      height: 60px;
      width: 100%;

      img {
        width: 25px;
        height: 25px;
        margin-left: 44px;
        margin-right: 26px;
      }

      span {
        color: #5B73A0;
        font-size: 1.1rem;
        font-weight: 600;
      }
    }

    &-info-title {
      font-size: 0.75rem;
      margin-left: 44px;
    }

    &-info-value {
      font-size: 0.7rem;
      margin-left: 60px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 15px 0 0 0;

      li {
        height: 60px;
        margin: 0;
        padding: 0;

        a {
          text-decoration: none;
        }
      }
    }
  }

  &-right {
    flex: 1;
  }
}
</style>
