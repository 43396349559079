<template>
  <div id="app">
    <!-- <van-nav-bar safe-area-inset-top /> -->
    <router-view/>
    <!-- <van-number-keyboard safe-area-inset-bottom /> -->
  </div>
</template>

<script lang="js">
import 'vant/lib/index.css';
import { NavBar, NumberKeyboard } from 'vant';

export default {
  name: 'App',
  components: {
    [NavBar.name]: NavBar,
    [NumberKeyboard.name]: NumberKeyboard,
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #2c3e50;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>
