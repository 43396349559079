<template>
  <div class="login">
    <div class="login-area">
      <van-field
        v-model="username"
        type="tel"
        label="手机号"
      />
      <van-field
        v-model="password"
        type="password"
        label="密码"
      />
      <van-button
        square
        type="primary"
        class="login-button"
        :disabled="!username || !password"
        @click="login"
      >
        登录
      </van-button>
    </div>
  </div>
</template>

<script lang="js">
import {
  Button,
  Field,
  Row,
  Col,
  CellGroup,
} from 'vant';

export default {
  name: 'UserLoginView',
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [Row.name]: Row,
    [Col.name]: Col,
    [CellGroup.name]: CellGroup,
  },
  emits: ['do-login'],
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    login() {
      this.$emit('do-login', {
        username: this.username,
        password: this.password,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  padding: 6px 0;
  &-area {
    padding: 80px 20px 20px 20px;
    text-align: center;
  }

  &-button {
    margin: 20px auto 0 auto;
    width: 50%;
  }
}
</style>
