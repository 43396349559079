<template>
  <div class="goal">
    <van-nav-bar
      title="销售目标"
      :left-text="navLeftText"
      :left-arrow="navLeftArrow"
      @click-left="onClickLeft"
    />
    <van-tabs
      v-model="active"
      type="card"
      color="#3a89c9"
    >
      <van-tab title="月份">
        <SaleGoalMonthView />
      </van-tab>
      <van-tab title="地域" class="goal-area">
        <van-tabs
          v-model="activeArea"
          type="line"
          color="#3a89c9"
        >
          <van-tab title="地域年度目标">
            <SaleGoalAreaView />
          </van-tab>
          <van-tab title="地域每月目标">
            <SaleGoalAgentView
              v-on:show-agent-detail="showAgent"
              v-if="!showAgentDetail"
            />
            <SaleGoalAgentProductView
              :agent-id="agentId"
              v-else
            />
          </van-tab>
        </van-tabs>
      </van-tab>
      <van-tab title="产品" class="goal-area">
        <van-tabs
          v-model="activeProduct"
          type="line"
          color="#3a89c9"
        >
          <van-tab title="产品年度目标">
            <SaleGoalProductYearView />
          </van-tab>
          <van-tab title="产品每月目标">
            <SaleGoalProductMonthView />
          </van-tab>
        </van-tabs>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script lang="js">
import {
  NavBar,
  Tabs,
  Tab,
} from 'vant';
import SaleGoalAgentView from '@/components/sale-goal/SaleGoalAgentView.vue';
import SaleGoalAreaView from '@/components/sale-goal/SaleGoalAreaView.vue';
import SaleGoalMonthView from '@/components/sale-goal/SaleGoalMonthView.vue';
import SaleGoalAgentProductView from '@/components/sale-goal/SaleGoalAgentProductView.vue';
import SaleGoalProductMonthView from '@/components/sale-goal/SaleGoalProductMonthView.vue';
import SaleGoalProductYearView from '@/components/sale-goal/SaleGoalProductYearView.vue';

export default {
  name: 'SaleGoalView',
  components: {
    [NavBar.name]: NavBar,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    SaleGoalAgentView,
    SaleGoalAreaView,
    SaleGoalMonthView,
    SaleGoalProductMonthView,
    SaleGoalAgentProductView,
    SaleGoalProductYearView,
  },
  data() {
    return {
      active: 0,
      activeArea: 0,
      activeProduct: 0,
      showAgentDetail: false,
      agentId: '',
    };
  },
  computed: {
    navLeftText() {
      let text = '返回';
      if (this.$route.name === 'salesman') {
        text = this.showAgentDetail ? '返回' : '';
      }
      return text;
    },
    navLeftArrow() {
      let arrow = true;
      if (this.$route.name === 'salesman') {
        arrow = this.showAgentDetail;
      }
      return arrow;
    },
  },
  methods: {
    onClickLeft() {
      if (this.active === 1 && this.showAgentDetail === true) {
        this.showAgentDetail = false;
      } else {
        this.$router.go(-1);
      }
    },
    showAgent($event) {
      this.showAgentDetail = true;
      this.agentId = $event;
    },
  },
};
</script>

<style lang="scss" scoped>
.goal-area {
  padding: 0;
  margin-top: 10px;
}
</style>
