<template>
  <div>
    <div class="goal">
      <div
        class="goal-item"
      >
        <van-row class="mt-20">
          <van-col span="24">
              <div class="goal-item-title">{{ year }}年度目标</div>
          </van-col>
        </van-row>
        <van-cell-group>
          <van-cell
            title="年度目标"
            :value="`${formatNumberToTenThousand(goodsObjectives)}万`"
          />
          <van-cell
            title="年度累计发货"
            :value="`${formatNumberToTenThousand(goodsObjectivesComplete)}万`"
          />
          <van-cell
            title="年度完成进度"
            :value="`${goodsObjectivesProgress}%`"
          />
          <van-cell
            title="截止当月累计目标"
            :value="`${formatNumberToTenThousand(cumulativeObjectives)}万`"
          />
          <van-cell
            title="截止当月累计进度"
            :value="`${cumulativeProgress}%`"
          />
        </van-cell-group>
      </div>
      <div
        class="goal-item"
      >
        <van-row class="mt-20">
          <van-col span="24">
              <div class="goal-item-title">Q{{ quarter }}发货目标</div>
          </van-col>
        </van-row>
        <van-cell-group>
          <van-cell
            title="当季季度目标"
            :value="`${formatNumberToTenThousand(quarterObjectives)}万`"
          />
          <van-cell
            title="当季累计发货"
            :value="`${formatNumberToTenThousand(quarterObjectivesComplete)}万`"
          />
          <van-cell
            title="当季完成进度"
            :value="`${quarterObjectivesProgress}%`"
          />
        </van-cell-group>
      </div>
      <div
        class="goal-item"
      >
        <van-row class="mt-20">
          <van-col span="24">
              <div class="goal-item-title">{{ month }}月发货目标</div>
          </van-col>
        </van-row>
        <van-cell-group>
          <van-cell
            title="当月月度目标"
            :value="`${formatNumberToTenThousand(monthObjectives)}万`"
          />
          <van-cell
            title="当月累计发货"
            :value="`${formatNumberToTenThousand(monthObjectivesComplete)}万`"
          />
          <van-cell
            title="当月完成进度"
            :value="`${monthObjectivesProgress}%`"
          />
        </van-cell-group>
      </div>
      <div
        class="goal-item"
        v-if="false"
      >
        <van-row class="mt-20">
          <van-col span="24">
            <div class="goal-item-title">访园目标</div>
          </van-col>
        </van-row>
        <van-cell-group>
          <van-cell
            title="年度目标"
            :value="`${visitObjectives}所`"
          />
          <van-cell
            title="当前发货"
            :value="`${visitObjectivesComplete}所`"
          />
          <van-cell
            title="完成进度"
            :value="`${visitObjectivesProgress}%`"
          />
        </van-cell-group>
      </div>
      <div
        v-for="(item, index) in trialObjectives"
        :key="index"
        class="goal-item"
      >
        <van-row gutter="20">
          <van-col span="24">
            <div class="goal-item-title">{{ item.name }}</div>
          </van-col>
        </van-row>
        <van-row gutter="20">
          <van-col span="8">
            <div class="goal-item-content">目标数量: {{ item.objectives }}所</div>
          </van-col>
          <van-col span="8">
            <div class="goal-item-content">开通试用: {{ item.trial_amount }}所</div>
          </van-col>
          <van-col span="8">
            <div class="goal-item-content">完成进度: {{ item.completed }}所</div>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="goal-action">
      <van-button
        type="primary"
        class="goal-action-button"
        block
        round
        color="#2D89F3"
        @click="goToSaleGoal"
      >
        查看详细目标拆解进度
      </van-button>
    </div>
  </div>
</template>

<script lang="js">
import {
  Row,
  Col,
  Button,
  Cell,
  CellGroup,
} from 'vant';
import api from '@/lib/api';

export default {
  name: 'MySaleGoal',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  props: {
    salesUserId: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      userId: '',
      year: new Date().getFullYear(),
      quarter: 1,
      month: new Date().getMonth() + 1,
      goodsObjectives: 0,
      visitObjectives: 0,
      trialObjectives: [],
      goodsObjectivesComplete: 0,
      visitObjectivesComplete: 0,
      goodsObjectivesProgress: 0,
      visitObjectivesProgress: 0,
      cumulativeObjectives: 0,
      cumulativeProgress: 0,
      quarterObjectives: 0,
      quarterObjectivesComplete: 0,
      quarterObjectivesProgress: 0,
      quarterCumulativeDifference: 0,
      quarterCumulativeDifferenceProgress: 0,
      monthObjectives: 0,
      monthObjectivesComplete: 0,
      monthObjectivesProgress: 0,
      monthCumulativeDifference: 0,
      monthCumulativeDifferenceProgress: 0,
    };
  },
  mounted() {
    if (!this.salesUserId || this.salesUserId === '') {
      this.userId = api.getUserInfoItem('id');
    } else {
      this.userId = this.salesUserId;
    }
    if (this.month <= 3) {
      this.quarter = 1;
    } else if (this.month <= 6) {
      this.quarter = 2;
    } else if (this.month <= 9) {
      this.quarter = 3;
    } else {
      this.quarter = 4;
    }
    this.getSaleGoal(this.userId, this.year);
    this.getMonthObjectiveList(this.userId, this.year);
  },
  methods: {
    getSaleGoal(userId, year) {
      api.getYearObjectives(userId, year).then((res) => {
        if (res.code === 200) {
          if (res.result) {
            this.goodsObjectives = parseFloat(res.result.goods_objectives.objectives, 10) || 0;
            this.visitObjectives = parseFloat(res.result.visit_objectives.objectives, 10) || 0;
            this.trialObjectives = parseInt(res.result.trial_objectives, 10) || 0;
            this.goodsObjectivesComplete = parseFloat(res.result.goods_objectives.completed, 10) || 0;
            this.visitObjectivesComplete = parseInt(res.result.visit_objectives.completed, 10) || 0;
            if (this.goodsObjectives !== 0) {
              this.goodsObjectivesProgress = (this.goodsObjectivesComplete / this.goodsObjectives * 100).toFixed(2);
            } else {
              this.goodsObjectivesProgress = 0;
            }
            if (this.visitObjectives !== 0) {
              this.visitObjectivesProgress = (this.visitObjectivesComplete / this.visitObjectives * 100).toFixed(2);
            } else {
              this.visitObjectivesProgress = 0;
            }
          }
        } else {
          this.$toast(res.message);
        }
      }).catch((err) => {
        this.$toast(err.message);
      });
    },
    getMonthObjectiveList(userId, year) {
      api.getMonthObjectives(userId, year).then((res) => {
        if (res.code === 200) {
          const monthList = res.result.monthly_list.sort((a, b) => a.month - b.month);
          const currentMonthObjectives = monthList.find((item) => item.month === this.month);
          if (currentMonthObjectives) {
            this.monthObjectives = parseFloat(currentMonthObjectives.objectives, 10) || 0;
            this.monthObjectivesComplete = parseFloat(currentMonthObjectives.completed, 10) || 0;
            if (this.monthObjectives !== 0) {
              this.monthObjectivesProgress = (this.monthObjectivesComplete / this.monthObjectives * 100).toFixed(2);
            } else {
              this.monthObjectivesProgress = 0;
            }
          }
          this.quarterObjectives = 0;
          this.quarterObjectivesComplete = 0;
          let quarterCumulativeObjectives = 0;
          let quarterCumulativeComplete = 0;
          let monthCumulativeObjectives = 0;
          let monthCumulativeComplete = 0;
          for (let i = 0; i < monthList.length; i += 1) {
            if (monthList[i].month <= this.month) {
              monthCumulativeObjectives += parseFloat(monthList[i].objectives, 10) || 0;
              monthCumulativeComplete += parseFloat(monthList[i].completed, 10) || 0;
            }
            if (monthList[i].month < (this.quarter - 1) * 3 + 1) {
              quarterCumulativeObjectives += parseFloat(monthList[i].objectives, 10) || 0;
              quarterCumulativeComplete += parseFloat(monthList[i].completed, 10) || 0;
            }
            if (monthList[i].month === (this.quarter - 1) * 3 + 1
              || monthList[i].month === (this.quarter - 1) * 3 + 2
              || monthList[i].month === (this.quarter - 1) * 3 + 3) {
              this.quarterObjectives += parseFloat(monthList[i].objectives, 10) || 0;
              this.quarterObjectivesComplete += parseFloat(monthList[i].completed, 10) || 0;
            }
          }
          this.cumulativeObjectives = monthCumulativeObjectives;
          if (this.cumulativeObjectives !== 0) {
            this.cumulativeProgress = (monthCumulativeComplete / this.cumulativeObjectives * 100).toFixed(2);
          } else {
            this.cumulativeProgress = 0;
          }
          this.quarterCumulativeDifference = quarterCumulativeObjectives - quarterCumulativeComplete;
          const quartMoreObjectives = quarterCumulativeObjectives + this.quarterObjectives;
          if (quartMoreObjectives !== 0) {
            this.quarterCumulativeDifferenceProgress = (this.quarterCumulativeDifference / quartMoreObjectives * 100).toFixed(2);
          } else {
            this.quarterCumulativeDifferenceProgress = 0;
          }
          this.monthCumulativeDifference = monthCumulativeObjectives - monthCumulativeComplete;
          const monthMoreObjectives = monthCumulativeObjectives + this.monthObjectives;
          if (monthMoreObjectives !== 0) {
            this.monthCumulativeDifferenceProgress = (this.monthCumulativeDifference / monthMoreObjectives * 100).toFixed(2);
          } else {
            this.monthCumulativeDifferenceProgress = 0;
          }
          if (this.quarterObjectives !== 0) {
            this.quarterObjectivesProgress = (this.quarterObjectivesComplete / this.quarterObjectives * 100).toFixed(2);
          } else {
            this.quarterObjectivesProgress = 0;
          }
        } else {
          this.$toast(res.message);
        }
      }).catch((err) => {
        this.$toast(err.message);
      });
    },
    formatNumberToTenThousand(value) {
      let num = 0;
      if (typeof value === 'string') {
        num = (parseFloat(value) / 10000).toFixed(2);
      } else {
        num = (value / 10000).toFixed(2);
      }
      return `${num}`;
    },
    goToSaleGoal() {
      this.$router.push({
        name: 'saleGoal',
        params: {
          userId: this.userId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.goal {
  margin-bottom: 60px;
}
.goal-item {
  background-color: #fff;
  margin: 20px 0;
  padding: 0 10px;

  &-content {
    font-size: 0.875rem;
    margin: 4px 0;
    text-align: left;
  }
}

.goal-item-title {
  font-size: 1rem;
  font-weight: bold;
  margin: 10px 0 4px 0;
  text-align: left;
}

.goal-action {
  bottom: calc(env(safe-area-inset-bottom) + 0px);
  left: 0;
  margin: 10px 0;
  position: fixed;
  text-align: center;
  width: 100%;

  &-button {
    margin: auto;
    width: 70%;
  }
}

.mt-20 {
  margin-top: 20px;
}

</style>
