<template>
  <div class="goal">
    <van-row class="goal-title">
      <van-col span="12" class="goal-title-cell">{{ agent.agentName }}</van-col>
      <van-col span="12">
        <van-row>
          <van-col span="6" class="center">
            <van-button
              type="default"
              size="small"
              plain
              hairline
              class="button-size"
              @click="month -= 1"
              :disabled="month === 1"
            >
              &lt;
            </van-button>
          </van-col>
          <van-col span="12" class="bottom-line center">{{ month }}月目标</van-col>
          <van-col span="6" class="center">
            <van-button
              type="default"
              size="small"
              plain
              hairline
              class="button-size"
              @click="month += 1"
              :disabled="month === 12"
            >
              &gt;
            </van-button>
          </van-col>
        </van-row>
        <van-row class="pr-8">
          <van-col span="8" class="right">计划</van-col>
          <van-col span="8" class="right">发货</van-col>
          <van-col span="8" class="right">进度</van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-list>
      <van-row
        v-for="(product, index) in agentProductList"
        :key="index"
        class="goal-product"
      >
        <van-col span="12" class="pl-8">{{ product.product_name }}</van-col>
        <van-col span="12">
          <van-row class="pr-8">
            <van-col span="8" class="right">{{ product.objectives[month - 1].objectives }}</van-col>
            <van-col span="8" class="right">{{ Math.round(product.objectives[month - 1].completed) }}</van-col>
            <van-col span="8" class="right">{{ calcProgress(product.objectives[month - 1].objectives, product.objectives[month - 1].completed) }}%</van-col>
          </van-row>
        </van-col>
      </van-row>
    </van-list>
  </div>
</template>

<script lang="js">
import {
  Row,
  Col,
  List,
  Cell,
  Button,
} from 'vant';
import api from '@/lib/api';

export default {
  name: 'SaleGoalAgentProductView',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [List.name]: List,
    [Cell.name]: Cell,
    [Button.name]: Button,
  },
  props: {
    agentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userId: '',
      agent: {},
      agentProductList: [],
      month: new Date().getMonth() + 1,
    };
  },
  filters: {
    formatNumberToTenThousand(value) {
      let num = 0;
      if (typeof value === 'string') {
        num = (parseFloat(value) / 10000).toFixed(2);
      } else {
        num = (value / 10000).toFixed(2);
      }
      return `${num}`;
    },
  },
  created() {
    this.userId = this.$route.params.userId;
  },
  mounted() {
    this.getAgentProductList();
  },
  methods: {
    getAgentProductList() {
      const year = new Date().getFullYear();
      api.getAgentProductObjectives(this.userId, this.agentId, year).then((res) => {
        if (res.code === 200) {
          const { agent_id: agentId, agent_name: agentName, product_list: list } = res.result;
          this.agent = { agentId, agentName };
          for (let i = 0; i < list.length; i += 1) {
            if (list[i].objectives.length !== 12) {
              const newObjectives = [];
              for (let j = 0; j < 12; j += 1) { // 12个月
                const monthObjective = list[i].objectives.find((item) => item.month === j + 1);
                if (monthObjective) {
                  newObjectives.push({ ...monthObjective });
                } else {
                  newObjectives.push({
                    month: j + 1,
                    objectives: 0,
                    completed: 0,
                  });
                }
              }
              list[i].objectives = [...newObjectives];
            }
          }
          this.agentProductList = list;
        } else {
          this.$toast(res.message);
        }
      }).catch((err) => {
        this.$toast(err.message);
      });
    },
    calcProgress(objectives, completed) {
      const obj = parseFloat(objectives);
      let percent = 0;
      if (obj === 0) {
        percent = 0;
      } else {
        percent = completed / obj * 100;
      }
      return percent.toFixed(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.goal {
  margin-top: 1rem;

  &-title {
    border-bottom: 1px solid #666;
    font-weight: bold;

    &-cell {
      line-height: 1.5rem;
      height: 3rem;
      text-align: center;
    }
  }

  &-product {
    border-bottom: 1px solid #666;
    line-height: 2rem;
  }
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.pl-8 {
  padding-left: 8px;
}

.button-size {
  width: 48px;
}

.bottom-line {
  border-bottom: 1px solid #666;
}
</style>
