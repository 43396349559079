<template>
  <div class="admin">
    <div class="admin-header">
      <div class="admin-header-logo">
        <img src="@/assets/logo.png" alt="logo" />
      </div>
      <div class="admin-header-content">
        <div
          class="admin-header-title"
        >
          <h1
            v-if="!isLogin"
          >
            洪恩销售管理系统
          </h1>
          <h1 v-else>{{ pageTitle }}</h1>
        </div>
        <div
          v-if="isLogin"
          class="admin-header-user"
        >
          <span>{{ userName }}</span>
          <a href="javascript:void(0)" @click="doLogout">退出</a>
        </div>
      </div>
    </div>
    <LoginView
      v-if="!isLogin"
      v-on:do-login="doLogin"
    />
    <AdminContentView
      v-else
    />
  </div>
</template>

<script lang="javascript">
import { decodeJwt } from 'jose';
import api from '@/lib/api';
import admin from '@/lib/admin';
import AdminContentView from '@/components/admin/AdminContentView.vue';
import LoginView from './LoginView.vue';

export default {
  name: 'AdminView',
  components: {
    LoginView,
    AdminContentView,
  },
  data() {
    return {
      isLogin: false,
      userName: '',
      pageTitle: '',
    };
  },
  watch: {
    $route() {
      if (this.$route.name === 'salesman') {
        this.pageTitle = api.getUserInfoItem('currentSalesman');
      } else {
        this.pageTitle = '看板';
      }
    },
  },
  created() {
    this.pageTitle = '看板';
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    checkLogin() {
      const userId = api.getUserInfoItem('id');
      const phone = api.getUserInfoItem('phone');
      const token = api.getUserInfoItem('token');
      const expireTime = api.getUserInfoItem('expires');
      if (!userId || !token || !expireTime) {
        this.isLogin = false;
        return;
      }
      const decoded = decodeJwt(token);
      if (!decoded) {
        this.isLogin = false;
        return;
      }
      if (decoded.exp * 1000 < Date.now()) {
        this.isLogin = false;
        return;
      }
      if (decoded.phone !== phone || decoded.id !== userId) {
        this.isLogin = false;
        return;
      }
      admin.checkLogin().then((response) => {
        if (response.code === 200) {
          this.isLogin = true;
          this.userName = api.getUserInfoItem('name');
          this.phone = api.getUserInfoItem('phone');
          if (this.$route.name === 'salesman') {
            this.pageTitle = api.getUserInfoItem('currentSalesman');
          }
        } else {
          api.clearUserInfo();
          this.isLogin = false;
        }
      }).catch((error) => {
        console.error('checkLogin error', error);
        this.isLogin = true; // 无法验证登录状态，暂时认为已登录
      });
    },
    doLogin(event) {
      const username = event.username ? event.username.trim() : '';
      const password = event.password ? event.password.trim() : '';
      if (!username || !password) {
        this.$toast('请输入用户名和密码');
        return;
      }
      admin.userLogin(username, password)
        .then((response) => {
          if (response.code === 200) {
            api.setUserInfoData(response.result);
            this.phone = api.getUserInfoItem('phone');
            this.$toast('登录成功');
            this.isLogin = true;
            this.userName = api.getUserInfoItem('name');
          } else {
            this.$toast('登录失败');
          }
        })
        .catch((error) => {
          console.error('login error', error);
          this.$toast('登录失败');
        });
    },
    doLogout() {
      admin.userLogout()
        .then(() => {
          api.clearUserInfo();
          this.$router.push({ name: 'admin' });
          this.isLogin = false;
        })
        .catch(() => {
          this.isLogin = false;
          api.clearUserInfo();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.admin {
  background-color: #ffffff;
  height: 100vh;
  width: 1200px;
  margin: 0 auto;

  &-header {
    background-color: #ffffff;
    border-bottom: 1px solid #E5E9F2;
    display: flex;
    height: 100px;
    justify-content: left;
    padding: 0 20px;

    &-logo {
      display: flex;
      align-items: center;
      text-align: center;
      width: 236px;

      img {
        height: 53px;
        margin: 24px auto auto auto;
        width: 136px;
      }
    }

    &-content {
      display: flex;
      align-items: left;
      width: 964px;
    }

    &-title {
      margin: 0;
      padding: 0;
      width: 100%;

      h1 {
        color: #343C6A;
        font-size: 2em;
        line-height: 100px;
        margin: 0;
        margin-left: 54px;
        padding: 0;
        text-align: left;
      }
    }

    &-user {
      display: flex;
      align-items: center;
      justify-content: right;
      margin: 0;
      padding: 0;
      width: 100%;

      span {
        color: #343C6A;
        font-size: 1.2em;
        margin-right: 20px;
      }

      a {
        color: #343C6A;
        font-size: 1.2em;
        text-decoration: none;
      }
    }
  }
}
</style>
