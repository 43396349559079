<template>
  <div class="home">
    <div
      v-if="!hasLogin"
    >
      <user-login-view
        v-on:do-login="login"
      />
    </div>
    <div
      v-else
    >
      <van-row>
        <van-col span="24">
          <div class="right">{{ userName }} {{ phone }}&nbsp;&nbsp;&nbsp;<a href="#" @click="logout">注销</a></div>
        </van-col>
      </van-row>
      <my-sale-goal />
      <!--
      <van-tabs
        v-model="active"
        type="card"
        color="#3a89c9"
      >
        <van-tab title="我的目标">
          <my-sale-goal />
        </van-tab>
        <van-tab title="我的日志" disabled>
          <my-sale-log />
        </van-tab>
        <van-tab title="我的台账" disabled>
          <my-standing-book />
        </van-tab>
      </van-tabs>
      -->
    </div>
  </div>
</template>

<script>
import {
  Tab,
  Tabs,
  Row,
  Col,
} from 'vant';
import { decodeJwt } from 'jose';
import api from '@/lib/api';
import MySaleGoal from '@/components/MySaleGoal.vue';
// import MySaleLog from '@/components/MySaleLog.vue';
// import MyStandingBook from '@/components/MyStandingBook.vue';
import UserLoginView from './UserLoginView.vue';

export default {
  name: 'HomeView',
  components: {
    MySaleGoal,
    // MySaleLog,
    // MyStandingBook,
    UserLoginView,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      active: 0,
      hasLogin: false,
      userName: '',
      phone: '',
    };
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    checkLogin() {
      const userId = api.getUserInfoItem('id');
      const phone = api.getUserInfoItem('phone');
      const token = api.getUserInfoItem('token');
      const expireTime = api.getUserInfoItem('expires');
      if (!userId || !token || !expireTime) {
        this.hasLogin = false;
        return;
      }
      const decoded = decodeJwt(token);
      if (!decoded) {
        this.hasLogin = false;
        return;
      }
      if (decoded.exp * 1000 < Date.now()) {
        this.hasLogin = false;
        return;
      }
      if (decoded.phone !== phone || decoded.id !== userId) {
        this.hasLogin = false;
        return;
      }
      api.checkLogin().then((response) => {
        if (response.code === 200) {
          this.hasLogin = true;
          this.userName = api.getUserInfoItem('name');
          this.phone = api.getUserInfoItem('phone');
        } else {
          this.hasLogin = false;
        }
      }).catch((error) => {
        console.error('checkLogin error', error);
        this.hasLogin = true; // 无法验证登录状态，暂时认为已登录
      });
    },
    login(event) {
      const username = event.username ? event.username.trim() : '';
      const password = event.password ? event.password.trim() : '';
      if (!username || !password) {
        this.$toast('请输入用户名和密码');
        return;
      }
      api.userLogin(username, password)
        .then((response) => {
          if (response.code === 200) {
            api.setUserInfoData(response.result);
            this.phone = api.getUserInfoItem('phone');
            this.userName = api.getUserInfoItem('name');
            this.$toast('登录成功');
            this.hasLogin = true;
          } else {
            this.$toast('登录失败');
          }
        })
        .catch((error) => {
          console.error('login error', error);
          this.$toast('登录失败');
        });
    },
    logout() {
      api.clearUserInfo();
      this.hasLogin = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 6px 0;
}

.right {
  margin: 10px 0;
  padding: 0 10px;
  text-align: right;
}
</style>
