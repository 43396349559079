import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AdminView from '../views/admin/AdminView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/saleGoal/:userId',
    name: 'saleGoal',
    component: () => import('../views/SaleGoalView.vue'),
  },
  {
    path: '/standingBookAgentList',
    name: 'standingBookAgentList',
    component: () => import('../views/StandingBookAgentListView.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
  },
  {
    path: '/admin/salesman/:userId',
    name: 'salesman',
    component: AdminView,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
