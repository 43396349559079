<template>
  <div class="login">
    <van-row class="login-label">
      <van-col span="5"></van-col>
      <van-col span="14">用户/手机号码</van-col>
      <van-col span="5"></van-col>
    </van-row>
    <van-row class="login-field">
      <van-col span="5"></van-col>
      <van-col span="14">
        <van-field
          v-model="username"
          type="tel"
          label=""
        />
      </van-col>
      <van-col span="5"></van-col>
    </van-row>
    <van-row class="login-label mt20">
      <van-col span="5"></van-col>
      <van-col span="14">用户</van-col>
      <van-col span="5"></van-col>
    </van-row>
    <van-row class="login-field">
      <van-col span="5"></van-col>
      <van-col span="14">
        <van-field
          v-model="password"
          type="password"
          label=""
        />
      </van-col>
      <van-col span="5"></van-col>
    </van-row>
    <van-row class="login-button">
      <van-col span="5"></van-col>
      <van-col span="14">
        <van-button
          square
          type="primary"
          class="login-button-button mt20"
          :disabled="!username || !password"
          @click="login"
        >
          登录
        </van-button>
      </van-col>
      <van-col span="5"></van-col>
    </van-row>
  </div>
</template>

<script lang="js">
import {
  Button,
  Field,
  Row,
  Col,
  CellGroup,
} from 'vant';

export default {
  name: 'UserLoginView',
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [Row.name]: Row,
    [Col.name]: Col,
    [CellGroup.name]: CellGroup,
  },
  emits: ['do-login'],
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    login() {
      this.$emit('do-login', {
        username: this.username,
        password: this.password,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  background-color: #ffffff;
  border-radius: 20px;
  color: #343C6A;
  font-size: 1.1rem;
  height: 400px;
  margin: 140px auto auto auto;
  padding: 46px 0 0 0;
  text-align: left;
  width: 640px;

  &-label {
    line-height: 25px;
    overflow: hidden;
  }

  &-field {
    margin-top: 4px;
    overflow: hidden;
  }

  &-button {
    margin-top: 50px;
    overflow: hidden;
    text-align: center;

    &-button {
      background-color: #2D60FF;
      border: none;
      border-radius: 10px;
      font-size: 1.2rem;
      height: 60px;
      margin: 0 auto;
      width: 50%;
    }
  }
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

::v-deep {
  .van-field {
    padding-left: 0;
    padding-right: 0;
  }

  .van-field__body {
    border: 1px solid #81D3F8;
    border-radius: 4px;
    font-size: 1.1rem;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
  }
}
</style>
