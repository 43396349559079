<template>
  <div class="goal">
    <van-row class="goal-title">
      <van-col span="12" class="center">产品名称</van-col>
      <van-col span="12">
        <van-row class="right pr-8">
          <van-col span="6">计划</van-col>
          <van-col span="7">发货</van-col>
          <van-col span="7">进度</van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-collapse v-model="activieProduct">
      <van-collapse-item
        v-for="(product, index) in productList"
        :key="index"
        :name="index"
        :title="product.product_name"
      >
        <template #value>
          <van-row>
            <van-col span="7" class="right">{{ product.objectives || 0 }}</van-col>
            <van-col span="8" class="right">{{ Math.round(product.completed || 0) }}</van-col>
            <van-col span="8" class="right">{{ calcProgress(product.objectives, product.completed) }}%</van-col>
          </van-row>
        </template>
        <van-row
          v-for="(agent, index) in product.agentList"
          :key="index"
          class="goal-agent"
        >
          <van-col span="11" class="goal-agent-name">
            {{ agent.agent_name }}
          </van-col>
          <van-col span="12">
            <van-row>
              <van-col span="8" class="right">{{ agent.objectives }}</van-col>
              <van-col span="7" class="right">{{ Math.round(agent.completed) }}</van-col>
              <van-col span="7" class="right">{{ calcProgress(agent.objectives, agent.completed) }}%</van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script lang="js">
import {
  Row,
  Col,
  List,
  Cell,
  Button,
  Collapse,
  CollapseItem,
} from 'vant';
import api from '@/lib/api';

export default {
  name: 'SaleGoalProductYearView',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [List.name]: List,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  data() {
    return {
      userId: '',
      productList: [],
      activieProduct: ['1'],
    };
  },
  filters: {
    formatNumberToTenThousand(value) {
      const num = (value / 10000).toFixed(2);
      return `${num}`;
    },
  },
  created() {
    this.userId = this.$route.params.userId;
  },
  mounted() {
    this.getProductList();
  },
  methods: {
    getProductList() {
      const year = new Date().getFullYear();
      api.getYearProductObjectives(this.userId, year).then((res) => {
        if (res.code === 200) {
          const list = res.result.list.reduce((acc, cur) => {
            const product = acc.find((item) => item.product_name === cur.product_name);
            if (product) {
              product.agentList.push(cur);
              product.objectives += parseFloat(cur.objectives);
              product.completed += parseFloat(cur.completed);
            } else {
              acc.push({
                product_id: cur.product_id,
                product_name: cur.product_name,
                agentList: [cur],
                objectives: parseFloat(cur.objectives),
                completed: parseFloat(cur.completed),
              });
            }
            return acc;
          }, []);
          this.productList = list;
        } else {
          this.$toast(res.message);
        }
      }).catch((err) => {
        this.$toast(err.message);
      });
    },
    calcProgress(objectives, completed) {
      const obj = parseFloat(objectives);
      let percent = 0;
      if (obj === 0) {
        percent = 0;
      } else {
        percent = completed / obj * 100;
      }
      return percent.toFixed(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.goal {
  margin-top: 1rem;

  &-title {
    border-bottom: 1px solid #666;
    font-weight: bold;

    &-cell {
      line-height: 3rem;
      height: 3rem;
    }
  }

  &-product {
    border-bottom: 1px solid #666;
    line-height: 2rem;
  }
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.button-size {
  width: 48px;
}

.bottom-line {
  border-bottom: 1px solid #666;
}
</style>
