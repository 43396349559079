import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    log: {
      id: '',
      date: new Date(),
      logTypeId: '',
      logTypeName: '',
      address: '',
      location: '',
      locationCity: '',
      transportStartTime: '',
      transportEndTime: '',
      photoUrl: '',
    },
  },
  getters: {
  },
  mutations: {
    updateLogId(state, id) {
      state.log.id = id;
    },
    updateLogDate(state, date) {
      state.log.date = date;
    },
    updateLogTypeId(state, logTypeId) {
      state.log.logTypeId = logTypeId;
    },
    updateLogTypeName(state, logTypeName) {
      state.log.logTypeName = logTypeName;
    },
    updateLogAddress(state, address) {
      state.log.address = address;
    },
    updateLogLocation(state, location) {
      state.log.location = location;
    },
    updateLogLocationCity(state, locationCity) {
      state.log.locationCity = locationCity;
    },
    updateLogTransportStartTime(state, transportStartTime) {
      state.log.transportStartTime = transportStartTime;
    },
    updateLogTransportEndTime(state, transportEndTime) {
      state.log.transportEndTime = transportEndTime;
    },
    updateLogPhotoUrl(state, photoUrl) {
      state.log.photoUrl = photoUrl;
    },
    clear(state) {
      state.log.id = '';
      state.log.date = new Date();
      state.log.logTypeId = '';
      state.log.logTypeName = '';
      state.log.address = '';
      state.log.location = '';
      state.log.locationCity = '';
      state.log.transportStartTime = '';
      state.log.transportEndTime = '';
      state.log.photoUrl = '';
    },
  },
  actions: {
  },
  modules: {
  },
});
